import classNames from 'classnames'
import { Form, Formik } from 'formik'
import { navigate } from 'gatsby'
import React from 'react'
import * as Yup from 'yup'
import Auth from 'components/Auth'
import Button from 'components/Button'
import FieldEmail from 'components/FieldEmail'
import Modal from 'components/Modal'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import storage from 'constants/storage'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import { generateFbc } from 'libs/analytics'
import * as assessments from 'libs/assessments'
import * as assessmentsApiRequest from 'libs/assessments-api-request'
import * as auth from 'libs/auth'
import * as cookie from 'libs/cookie'
import * as enterprise from 'libs/enterprise'
import * as notifications from 'libs/notifications'
import * as url from 'libs/url'
import * as user from 'libs/user'
import * as userApiRequest from 'libs/user-api-request'
import * as validations from 'libs/validations'
import styles from './SignUpEmailForm.module.scss'

type Props = {
  axis?: 'x' | 'y'
  buttonProps?: {}
  fieldProps?: {}
  fieldAndButtonProps?: {}
  inputProps?: {}
} & Component

export default function SignUpEmailForm({
  axis = 'x',
  buttonProps = {},
  className,
  fieldProps = {},
  fieldAndButtonProps = { size: 'xlarge' },
}: Props) {
  const globalContext = useGlobalContext()
  const [showSignIn, setShowSignIn] = React.useState(false)

  const externalId = cookie.getCookie(storage.ENTERPRISE_EXTERNAL_ID_KEY)
  const partner = globalContext.partner || cookie.getCookie(storage.HOME_KEY)

  async function submit(values, formikActions) {
    const {
      ad_id,
      fbclid,
      referral,
      src_id,
      utm_adgroup,
      utm_campaign,
      utm_content,
      utm_medium,
      utm_source,
    } = url.getQueryString()
    if (fbclid) cookie.createCookie(storage.FBC_LID, fbclid)

    const from = globalContext.eventData[events.SIGN_UP_FROM_KEY]
    const heroTitle = globalContext.eventData[events.HOME_HERO_TITLE_KEY]
    const srcId = fbclid || ad_id ? user.FACEBOOK_SRC_ID : (src_id ?? partner)

    const response = await userApiRequest
      .signUpUser({
        currentURL: window.location.href,
        email: values.email.trim(),
        externalId, // reef or orca code in URL
        referredBy: referral,
        srcId,
        utm_adgroup,
        utm_campaign,
        utm_content,
        utm_medium,
        utm_source,
      })
      .catch((error: any) => {
        console.error(error)
        notifications.notifyError()
        formikActions.setSubmitting(false)
      })

    const eventData = {
      referralId: referral,
      srcId,
    }

    if (response.message || response.error) {
      const message = response.message || response.error.message
      console.error(message)

      const isExistingUser = response?.code === 422
      const emailInvalid = message === 'Validation error: Validation isEmail on email failed'
      if (isExistingUser) {
        globalContext.update({ existingUserEmail: response?.data?.email || email })
        setShowSignIn(true)
      } else if (emailInvalid) {
        notifications.notifyError(validations.EMAIL_INVALID_MESSAGE)
      } else {
        notifications.notifyError()
      }

      formikActions.setSubmitting(false)

      await globalContext.analytics?.trackEvent(
        events.SIGN_UP_FAILED,
        {
          ...eventData,
          ad_id,
          error: isExistingUser ? 'existing user' : 'failed signUp api call',
          heroTitle,
          signupType: events.SIGN_UP_TYPE.EMAIL,
        },
        {
          utm_adgroup,
          utm_campaign,
          utm_content,
          utm_medium,
          utm_source,
        }
      )

      return
    }

    const { data: responseData } = response

    auth.setSessionData(responseData)

    await globalContext.update({ user: responseData.user }, async (updatedGlobalContext) => {
      const locallyStoredAssessmentObject = assessments.getLocallyStoredAssessmentDetails()

      const assessmentId = locallyStoredAssessmentObject.id
      const assessmentName = locallyStoredAssessmentObject.name
      const assessmentResult = locallyStoredAssessmentObject.result

      const enterpriseName = responseData.user?.enterpriseMember?.enterprise?.internalId

      await updatedGlobalContext.analytics?.trackEvent(
        events.SIGN_UP,
        {
          ...eventData,
          assessmentId,
          assessmentName,
          external_id: responseData.user?.id,
          from,
          heroTitle,
          signupType: events.SIGN_UP_TYPE.EMAIL,
          fbc: generateFbc(fbclid),
        },
        {
          enterpriseName,
          id: responseData.user?.id,
          utm_adgroup,
          utm_campaign,
          utm_content,
          utm_medium,
          utm_source,
        }
      )

      if (assessmentId && assessmentResult && responseData.user.id) {
        await assessmentsApiRequest.submitUserResult({
          assessmentId,
          userRecord: assessmentResult,
        })
      }

      notifications.notifySuccess(
        //todo check copy..
        `${referral ? 'Congratulations, you successfully redeemed your referral.' : ''}
        Thanks for signing up. We are glad you are here.`
      )

      navigate(
        enterpriseName
          ? paths.ONBOARDING_ENTERPRISE_ELIGIBILITY
          : enterprise.skipEligibilityCheck(srcId)
            ? paths.ONBOARDING_INTRO // mass-advantage goes straight to OB
            : paths.ONBOARDING_ENTERPRISE_ELIGIBILITY_RECHECK
      )
    })
  }

  async function handleSubmit(values, formikActions) {
    return await submit(values, formikActions)
  }

  return (
    <div className={classNames('SignUpEmailForm', styles[`this---${axis}`], className)}>
      <Formik
        enableReinitialize
        initialValues={{
          email: '',
        }}
        validateOnBlur={false}
        validationSchema={Yup.object({
          email: validations.EMAIL,
        })}
        onSubmit={handleSubmit}>
        {(formikProps) => (
          <Form className={styles.form}>
            <FieldEmail
              {...fieldProps}
              {...fieldAndButtonProps}
              flush
              label="Enter your email address"
              labelAsPlaceholder
              className={styles.field}
            />
            <Button
              {...buttonProps}
              {...fieldAndButtonProps}
              disabled={formikProps.isSubmitting}
              className={styles.button}
              onClick={formikProps.submitForm}>
              <Text wrap={false}>{buttonProps.children || 'Sign up'}</Text>
            </Button>
          </Form>
        )}
      </Formik>
      <Modal isOpen={showSignIn} size="xlarge" onRequestClose={() => setShowSignIn(false)}>
        <Auth type="sign-in" />
      </Modal>
    </div>
  )
}
